// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-posts-tsx": () => import("./../../../src/pages/posts.tsx" /* webpackChunkName: "component---src-pages-posts-tsx" */),
  "component---src-pages-sp-2-ap-authorized-tsx": () => import("./../../../src/pages/sp2ap/authorized.tsx" /* webpackChunkName: "component---src-pages-sp-2-ap-authorized-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */)
}

